<template>
  <!--注册页面-->
  <div class="content register-page">
    <!--表单-->
    <div class="login-box">
      <!--头-->
      <div class="header">
        <!--标题-->
        <h6 class="title">注册</h6>

        <!--右侧按钮-->
        <div class="title-right">
          已有账号?
          <el-button type="text" @click="goBack">去登录</el-button>
        </div>
      </div>
      <!--表单-->
      <el-form ref="PrivateRegister" :model="PrivateRegister" :rules="PrivateRules" size="medium">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item prop="phone">
              <el-input
                v-model="PrivateRegister.phone"
                v-on:input="getPhoneString"
                placeholder="手机号"
                maxlength="11"
                @input="changeValue"
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item key="vf_input" prop="code">
              <div class="d-flex flex-align-h-between">
                <el-input v-model.trim="PrivateRegister.code" placeholder="验证码" maxlength="6" />

                <div class="ml-20" style="width: 130px;flex:0 0 auto">
                  <el-button
                    v-if="AuthCode2"
                    type="primary"
                    class="width-full"
                    @click="PrivateCode(PrivateRegister.phone)"
                  >
                    获取验证码
                  </el-button>
                  <el-button v-else disabled type="primary" class="width-full again-btn">
                    重新获取({{ auth_time2 }}s)
                  </el-button>
                </div>
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="email">
              <el-input v-model="PrivateRegister.email" placeholder="邮箱" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="name">
              <el-input
                v-model="PrivateRegister.name"
                v-on:input="getPhoneString"
                placeholder="昵称"
                maxlength="30"
                show-word-limit
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="password">
              <el-input
                v-model.trim="PrivateRegister.password"
                :type="passwordType ? 'text' : 'password'"
                placeholder="密码"
                maxlength="16"
              >
                <svg-icon
                  slot="suffix"
                  :icon-class="passwordType ? 'eye-open' : 'eye'"
                  @click="passwordType = !passwordType"
                />
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="password2">
              <el-input
                v-model.trim="PrivateRegister.password2"
                :type="passwordType2 ? 'text' : 'password'"
                placeholder="重复密码"
                maxlength="16"
              >
                <svg-icon
                  slot="suffix"
                  :icon-class="passwordType2 ? 'eye-open' : 'eye'"
                  @click="passwordType2 = !passwordType2"
                />
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!--备注-->
      <div class="remark">6-16个字符，可包含数字、字母及符号。</div>

      <div class="text-center">
        <el-button
          type="primary"
          size="medium"
          @click="PrivateFormat"
          class="mb-15"
          style="width:317px"
        >
          注 册
        </el-button>
        <div class="footer">
          <!--<el-checkbox v-model="agreement">注册即表示您已同意本平台</el-checkbox>-->
          注册即表示您已同意本平台
          <el-button type="text" @click="contract">服务协议</el-button>
          <span>和</span>
          <el-button type="text" @click="Privacy">隐私政策</el-button>
        </div>
      </div>
    </div>
  </div>
  <!--内容-->
  <!--<el-tabs>
    &lt;!&ndash; <el-tab-pane label="企业注册">
      <el-form
        ref="CompanyRegister"
        :model="CompanyRegister"
        :rules="CompanyRules"
        label-position="left"
        label-width="100px"
      >
        <el-row type="flex" align="middle" justify="center" class="flex-wrap">
          <el-col :span="12">
            <el-form-item label="企业名称" prop="teamName">
              <el-input
                v-model.trim="CompanyRegister.teamName"
                placeholder="请输入企业名称"
                maxlength="30"
              />
            </el-form-item>

            <el-form-item label="用户昵称" prop="userName">
              <el-input
                v-model.trim="CompanyRegister.userName"
                placeholder="请输入用户昵称"
                maxlength="30"
              />
            </el-form-item>

            <el-form-item label="统一社会信用码" label-width="150px" prop="creditCode">
              <el-input
                v-model.trim="CompanyRegister.creditCode"
                placeholder="请输入统一社会信用码"
                maxlength="18"
              />
            </el-form-item>

            <el-form-item label="区域" prop="province">
              <el-select
                v-model.trim="CompanyRegister.province"
                placeholder="请选择区域"
                style="width:100%"
              >
                <el-option
                  v-for="item in Province"
                  :key="item.id"
                  :label="item.province"
                  :value="item.provinceid"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model.trim="CompanyRegister.phone"
                v-on:input="getPhoneString"
                placeholder="请输入手机号"
                maxlength="11"
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="设置密码" prop="password">
              <el-input
                v-model.trim="CompanyRegister.password"
                type="password"
                show-password
                placeholder="请输入密码"
                maxlength="16"
              />
            </el-form-item>

            <el-form-item label="确认密码" prop="password2">
              <el-input
                v-model.trim="CompanyRegister.password2"
                type="password"
                placeholder="请再次输入密码"
                maxlength="16"
                show-password
              />
            </el-form-item>

            <el-form-item label="电子邮箱" prop="email">
              <el-input v-model.trim="CompanyRegister.email" placeholder="请输入邮箱" />
            </el-form-item>

            <el-form-item>
              <div style="height:40px;"></div>
            </el-form-item>

            <el-form-item label="验证码" key="vf_input" prop="code">
              <el-row type="flex">
                <el-col :span="14">
                  <el-input
                    v-model.trim="CompanyRegister.code"
                    placeholder="请输入验证码"
                    maxlength="6"
                  />
                </el-col>

                <el-col :span="10" class="text-right">
                  <el-button
                    v-show="AuthCode === true"
                    type="text"
                    @click="sendCompanyCode(CompanyRegister.phone)"
                  >
                    获取验证码
                  </el-button>

                  <el-button v-show="!AuthCode" type="text">
                    {{ auth_time }}秒后重新发送
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="mb-20">
          <el-checkbox v-model.trim="agreement">已阅读并同意</el-checkbox>
          <el-button type="text" @click="contract">《印工社用户服务协议》</el-button>
          和
          <el-button type="text" @click="Privacy">《隐私政策声明》</el-button>
        </div>

        <div class="text-center">
          <el-button type="primary" @click="registFunc" class="width-full big-btn w-400">
            立即注册
          </el-button>
          <el-button type="text" @click="goBack" style="position: absolute;right:0">
            返回登录
          </el-button>
        </div>
      </el-form>
    </el-tab-pane> &ndash;&gt;
    <el-tab-pane label="个人注册">
    </el-tab-pane>
  </el-tabs>-->
</template>

<script>
import API from '../../api';

export default {
  name: 'registerPage',
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.CompanyRegister.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    const validatePass3 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.PrivateRegister.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      passwordType: false,
      passwordType2: false,
      tab: 1, // 切换注册用户
      PrivateRegister: {
        phone: '', // 手机号
        code: '', // 验证码
        password: '', // 密码
        password2: '', // 重复密码
        name: '' // 名称
      },
      PrivateRules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9][0-9]{9}$/, message: '请输入正确手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { pattern: /^\d{6}$/, message: '请输入正确验证码格式', trigger: 'blur' }
        ],
        name: [{ required: true, trigger: 'blur', message: '请输入账号昵称' }],
        email: [
          { required: true, trigger: 'blur', message: '请输入邮箱' },
          {
            pattern: /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/,
            message: '请输入正确的邮箱地址',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /^[\w!@#$%^&*]{6,16}$/,
            message: '请输入6-16位密码，可包含数字、字母、!@#$%^&*_',
            trigger: 'blur'
          }
        ],
        password2: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: validatePass3, trigger: 'blur' }
        ]
      },
      agreement: false, // 协议勾选
      CompanyRegister: {
        code: '',
        email: '',
        password: '',
        password2: '',
        phone: '',
        province: '', // 省份
        teamName: '',
        creditCode: '', // 信用码
        userName: '' // 用户名
      },
      CompanyRules: {
        teamName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        creditCode: [
          { required: true, message: '请输入统一社会信用码', trigger: 'blur' },
          {
            pattern: /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/,
            message: '请输入正确的格式',
            trigger: 'blur'
          }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9][0-9]{9}$/, message: '请输入正确手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /^[\w!@#$%^&*]{4,16}$/,
            message: '请输入4-16位密码，包含字母、数字、!@#$%^&*_',
            trigger: 'blur'
          }
        ],
        password2: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { pattern: /^\d{6}$/, message: '请输入正确验证码格式', trigger: 'blur' }
        ],
        province: [{ required: true, message: '请选择区域', trigger: 'change' }],
        email: [
          { required: true, message: '请输入电子邮箱', trigger: 'blur' },
          {
            pattern: /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/,
            message: '请输入正确的邮箱地址',
            trigger: 'blur'
          }
        ],
        userName: [{ required: true, message: '请输入用户名', trigger: 'blur' }]
      },
      // 省份
      Province: [],
      activeName: 'second',
      AuthCode: true, // 控制验证码显示
      AuthCode2: true,
      auth_time: 60, // 验证码倒计时
      auth_time2: 60,
      closePassWord: false
    };
  },
  created() {
    document.title = '注册';
    // this.getProvince()
  },
  methods: {
    changeValue(value) {
      this.PrivateRegister.phone = value.replace(/[^\d]/g, '');
    },
    // 协议
    contract() {
      const routeData = this.$router.resolve({ path: '/agreement' });
      window.open(routeData.href, '_blank');
      // window.open('https://www.ygspii.cn/page_agreement_regist.html')
      // window.open('https://www.cpct.net.cn/page_agreement_regist.html')
    },
    // 隐私
    Privacy() {
      const routeData = this.$router.resolve({ path: '/privacy' });
      window.open(routeData.href, '_blank');
      // window.open('https://www.ygspii.cn/page_agreement_privacyPolicy.html')
      // window.open('https://www.cpct.net.cn/page_agreement_privacyPolicy.html')
    },

    getPhoneString() {
      this.PrivateRegister.phone = this.PrivateRegister.phone.replace(' ', '');
    },
    // 返回登录
    BackLogin() {
      this.$router.push('/login');
    },
    // 获取省份
    getProvince() {
      API.Province()
        .then((res) => {
          if (res.code === '200') {
            this.Province = res.message.data;
          }
        })
        .catch((mgs) => {
          this.$message({
            message: mgs.message,
            type: 'error',
            duration: 1000
          });
        });
    },
    // 个人手机号格式
    checkAccount() {
      const reg = /^1[3|4|5|7|8|9][0-9]{9}$/;
      const res = reg.test(this.PrivateRegister.phone);
      return res;
    },
    // 个人验证码格式
    cheackCode() {
      const reg = /^\d{6}$/;
      const res = reg.test(this.PrivateRegister.code);
      return res;
    },
    // 公司手机号格式
    checkAccount2() {
      const reg = /^1[3|4|5|7|8|9][0-9]{9}$/;
      const res = reg.test(this.CompanyRegister.phone);
      return res;
    },
    // 公司验证码格式
    cheackCode2() {
      const reg = /^\d{6}$/;
      const res = reg.test(this.CompanyRegister.code);
      return res;
    },
    // 个人发送验证码
    judgeCode() {
      if (this.PrivateRegister.phone.length <= 0) {
        this.$message({
          message: '手机号不能为空',
          type: 'error',
          duration: 1000
        });
      } else if (!this.checkAccount()) {
        this.$message({
          message: '请输入正确的手机号',
          type: 'error',
          duration: 1000
        });
      } else {
        this.sendCode();
        this.AuthCode = false;
        this.auth_time = 60;
        const AuthTimetimer = setInterval(() => {
          this.auth_time -= 1;
          if (this.auth_time <= 0) {
            this.AuthCode = true;
            clearInterval(AuthTimetimer);
          }
        }, 1000);
      }
    },
    // 个人发送验证码
    sendCode() {
      API.sendCode(this.PrivateRegister)
        .then((res) => {
          if (res.success === true) {
            this.$message({
              message: res.message.message,
              type: 'success',
              duration: 1000
            });
          } else {
            this.$message({
              message: res.message.message,
              type: 'warning',
              duration: 1000
            });
          }
        })
        .catch((mgs) => {
          this.$message({
            message: mgs.message.message,
            type: 'error',
            duration: 1000
          });
        });
    },

    // 判断个人注册输入格式
    PrivateFormat() {
      this.$refs.PrivateRegister.validate((valid) => {
        if (valid) {
          // if (this.agreement == false) {
          //   this.$message({
          //     message: '请勾选并同意协议',
          //     type: 'error',
          //     duration: 1000
          //   })
          //   return
          // }
          this.Private();
        }
      });
      // if (this.PrivateRegister.phone.length <= 0) {
      //   this.$message({
      //     message: '手机号不能为空',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (!this.checkAccount()) {
      //   this.$message({
      //     message: '请输入正确的手机号',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (this.PrivateRegister.code.length <= 0) {
      //   this.$message({
      //     message: '请输入验证码',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (!this.cheackCode()) {
      //   this.$message({
      //     message: '验证码格式错误',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (this.PrivateRegister.password.length <= 0) {
      //   this.$message({
      //     message: '请输入密码',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (this.PrivateRegister.password2.length <= 0) {
      //   this.$message({
      //     message: '请再次输入密码',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (this.PrivateRegister.password !== this.PrivateRegister.password2) {
      //   this.$message({
      //     message: '两次密码不一致',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (this.agreement == false) {
      //   this.$message({
      //     message: '请勾选并同意协议',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else
    },
    registFunc() {
      this.$refs.CompanyRegister.validate((valid) => {
        if (valid) {
          if (!this.agreement) {
            this.$message({
              message: '请勾选并同意协议',
              type: 'error',
              duration: 1000
            });
            return;
          }
          API.CompanyRegister(this.CompanyRegister)
            .then((res) => {
              if (res.success) {
                this.$message({
                  message: res.message.message,
                  type: 'success',
                  duration: 2000
                });
                let time = 3;
                const timer = setInterval(() => {
                  time -= 1;
                  if (time === 0) {
                    clearInterval(timer);
                    this.$router.go(-1);
                  }
                }, 1000);
              } else {
                this.$message({
                  message: res.message.message,
                  type: 'error',
                  duration: 1000
                });
              }
            })
            .catch((mgs) => {
              this.$message({
                message: mgs.message.message,
                type: 'error',
                duration: 1000
              });
            });
        }
      });
    },
    // 个人注册
    Private() {
      // this.PrivateRegister.name = this.PrivateRegister.phone
      API.PrivateRegister(this.PrivateRegister)
        .then((res) => {
          if (res.message.success) {
            this.$message({
              message: res.message.message,
              type: 'success',
              duration: 1000
            });
            let time = 3;
            const timer = setInterval(() => {
              time -= 1;
              if (time === 0) {
                clearInterval(timer);
                this.$router.go(-1);
              }
            }, 1000);
          } else {
            this.$message({
              message: res.message.message,
              type: 'error',
              duration: 1000
            });
          }
        })
        .catch((mgs) => {
          this.$message({
            message: mgs.message.message,
            type: 'error',
            duration: 1000
          });
        });
    },
    checkPhone(value) {
      const reg = /^1[3|4|5|7|8|9][0-9]{9}$/;
      const res = reg.test(value);
      return res;
    },
    // 公司注册
    // Company() {
    //   this.$refs['CompanyRegister'].validate(valid => {
    //     if (valid) {
    //       if (!this.checkPhone()) {
    //         this.$message({
    //           message: '请输入正确的手机号',
    //           type: 'error',
    //           duration: 1000
    //         })
    //       } else if (this.CompanyRegister.password !== this.CompanyRegister.password2) {
    //         this.$message({
    //           message: '两次密码不一致',
    //           type: 'warning',
    //           duration: 1000
    //         })
    //       } else if (!this.cheackCode2()) {
    //         this.$message({
    //           message: '请输入正确验证码格式',
    //           type: 'warning',
    //           duration: 1000
    //         })
    //       } else if (this.agreement == false) {
    //         this.$message({
    //           message: '请勾选协议',
    //           type: 'warning',
    //           duration: 1000
    //         })
    //       } else {
    //         API.CompanyRegister(this.CompanyRegister)
    //           .then(res => {
    //             if (res.success == true) {
    //               this.$message({
    //                 message: res.message.message,
    //                 type: 'success',
    //                 duration: 1000
    //               })
    //               this.BackLogin()
    //             } else {
    //               this.$message({
    //                 message: res.message.message,
    //                 type: 'warning',
    //                 duration: 1000
    //               })
    //             }
    //           })
    //           .catch(mgs => {
    //             this.$message({
    //               message: mgs.message.message,
    //               type: 'error',
    //               duration: 1000
    //             })
    //           })
    //       }
    //     }
    //   })
    // },
    // 公司和个人发送验证码接口
    getCompanCode(phone) {
      API.sendCode({ phone })
        .then((res) => {
          if (res.message.success === true) {
            this.$message({
              message: res.message.message,
              type: 'success',
              duration: 1000
            });
            this.AuthCode2 = false;
            this.auth_time2 = 60;
            const AuthTimetimer = setInterval(() => {
              this.auth_time2 -= 1;
              if (this.auth_time2 <= 0) {
                this.AuthCode2 = true;
                clearInterval(AuthTimetimer);
              }
            }, 1000);
          } else {
            this.$message({
              message: res.message.message,
              type: 'error',
              duration: 1000
            });
          }
        })
        .catch((mgs) => {
          this.$message({
            message: mgs.message.message,
            type: 'error',
            duration: 1000
          });
        });
    },
    // 个人注册发送验证码
    PrivateCode(phone) {
      if (this.PrivateRegister.phone.length <= 0) {
        this.$message({
          message: '请输入手机号',
          type: 'error',
          duration: 1000
        });
        return;
      }
      if (!this.checkPhone(this.PrivateRegister.phone)) {
        this.$message({
          message: '请输入正确的手机号',
          type: 'error',
          duration: 1000
        });
        return;
      }
      this.getCompanCode(phone);
    },
    // 公司注册发送验证码
    // sendCompanyCode(phone) {
    //   if (this.CompanyRegister.phone.length <= 0) {
    //     this.$message({
    //       message: '请输入手机号',
    //       type: 'error',
    //       duration: 1000
    //     })
    //     return
    //   }
    //   this.getCompanCode(phone)
    //   this.AuthCode2 = false
    //   this.auth_time2 = 60
    //   let AuthTimetimer = setInterval(() => {
    //     this.auth_time2--
    //     if (this.auth_time2 <= 0) {
    //       this.auth_time2 = 60
    //       this.AuthCode2 = true
    //       clearInterval(AuthTimetimer)
    //     }
    //   }, 1000)
    // },
    goBack() {
      document.title = '登录';
      if (this.$route.query.path === '/login/:SUP/') {
        this.$router.push('/login/:SUP/');
      } else {
        this.$router.push('/login/:PORTAL/');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@media screen and (max-width:800px) {
  .Home {
    min-width: 320px !important;
  }
  .login-box {
    width: 100%;
    height: 600px;
    padding: 50px 20px 0;
  }
  .login-box .left{
    display: none;
  }
  .login-box .right {
    width: 100%;
    padding: 50px 20px 0 !important;
  }
  .footer {
    font-size: 12px;
  }
  .text {
  //font-size: 12px;
  }
  .el-button {
    font-size: 13px;
  }
  .content {
    margin-top: 50px;
    align-items: baseline;
  }
  .mb-15 {
    width: 100% !important;
  }
  .el-col-12 {
    width: 100%!important;
  }
}
</style>
